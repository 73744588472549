$highValue: #63B585;
$repeat: #64BAF7;
$oneTime: #A1A2C1;
$new: #072AB5;
$lapsed: #EFAE2D;
$lost: #FF1336;
$defaultRFM: #072AB5;

$active: #072AB5;
$stays:#141322; 
$growing:#41b883;
$declines:#faad15;
$reactivated: #4b7cf3;
$atRisk: #f12a5c;
$lostMigration:  #8b081b;
$newMigration: #a1a1c2;